import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, NgClass, NgFor } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';

import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { Subject, takeUntil } from 'rxjs';

import { Company } from '../../model/company';
import { CompanyLocation } from '../../model/company-location';
import { OpeningTimesComponent } from '../opening-times/opening-times.component';
import { environment } from '../../../../environments/environment';
import { Nl2brPipe } from '../../pipes/nl2br.pipe';

@Component({
    selector: 'app-about',
    standalone: true,
    imports: [
        NgFor,
        NgClass,
        TranslocoDirective,
        MatIconModule,
        OpeningTimesComponent,
        Nl2brPipe
    ],
    templateUrl: './about.component.html',
    styleUrl: './about.component.scss'
})
export class AboutComponent implements OnInit {
    @Input() companyInfo!: Company;
    @Input() companyLocation!: CompanyLocation;

    googleMapUrl!: SafeResourceUrl;
    isBrowser = false;
    description = '';

    private componentDestroyed$ = new Subject<void>();

    constructor(
        private sanitizer: DomSanitizer,
        private translocoService: TranslocoService,
        @Inject(PLATFORM_ID) private platformId: object
    ) {}

    ngOnInit(): void {
        this.googleMapUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.getGoogleMapUrl()
        );

        this.isBrowser = isPlatformBrowser(this.platformId);
        this.createDescription();
        this.translocoService.langChanges$
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(() => {
                this.googleMapUrl =
                    this.sanitizer.bypassSecurityTrustResourceUrl(
                        this.getGoogleMapUrl()
                    );
            });
    }

    ngOnDestroy(): void {
        this.componentDestroyed$.next();
        this.componentDestroyed$.complete();
    }

    /**
     * Creates the description.
     */
    private createDescription(): void {
        if (this.companyInfo.description) {
            this.description = this.companyInfo.description;
        }

        if (this.companyInfo.description && this.companyLocation.description) {
            this.description += '<br><br>';
        }

        if (this.companyLocation.description) {
            this.description += this.companyLocation.description;
        }
    }

    /**
     * Gets the Google map url
     * @returns {string} - The Google map url.
     */
    private getGoogleMapUrl(): string {
        return (
            'https://www.google.com/maps/embed/v1/place?key=' +
            environment.googleMapKey +
            '&q=' +
            this.companyLocation.address +
            '&language=' +
            this.translocoService.getActiveLang()
        );
    }
}
